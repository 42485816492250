import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import SingleNotebook from "./pages/noteBook";
import SingleNote from "./pages/singleNote";
import NotebookOverview from "./pages/noteBookOverview";
import './App.css'

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <NotebookOverview/>,
        },
        {
            path: '/notebook',
            element: <SingleNotebook/>
        },
        {
            path: '/note',
            element: <SingleNote/>
        }
    ]);

    React.useEffect(() => {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.visualViewport!.height * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.getElementById('root')!.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.visualViewport!.height * 0.01;
            document.getElementById('root')!.style.setProperty('--vh', `${vh}px`);
        });
        try {
            const permitted = navigator.storage.persist()
            console.log('Successfully registered persistent storage.', permitted)
        } catch(e) {console.error('Could not request persisted storage!')}
    }, [])
    return (
        <div className="App">
            <div className="bg">
                <img className={"bglogo"} src={"bglogo.png"} alt={""}/>
            </div>
            <div id={"debug"} style={{position: 'fixed', top: 0, left: 0, color: "red"}}></div>
            <RouterProvider router={router}/>
        </div>
    );
}

export default App;
