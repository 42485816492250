import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {deleteNote, loadNote, loadNotebook, INoteObject, NOTE_DATAVERSION, storeNote} from "../dbaccess";
import hljs from "highlight.js";
import {uuidv4, downloadJson} from "../utils";
import {post_notebook_view_render_hooks} from '../utils'
import {isArrowNavigation, arrowNavigation, scrollDownIfNecessary} from '../utils/navigation'
import './noteBook.css'

let selectedTileId = ''


function TileSidebar(props: any) {

    function changePriority(prio: number) {
        if (prio == props.prio) {prio = 0}
        props.setprio(prio)
    }
    const sidebarStyle = {}
    const prio3_cls = ['priofragment']
    if (props.prio === 3) {prio3_cls.push('active')}
    const prio2_cls = ['priofragment']
    if (props.prio === 2) {prio2_cls.push('active')}
    const prio1_cls = ['priofragment']
    if (props.prio === 1) {prio1_cls.push('active')}
    // @ts-ignore
    if (props.prio === 0) {sidebarStyle['opacity'] = 0}
    return <div className="tilesidebar" style={sidebarStyle}>
        <div onClick={() => changePriority(3)} className={prio3_cls.join(' ')} style={{'height': '100%'}}></div>
        <div onClick={() => changePriority(2)} className={prio2_cls.join(' ')} style={{'height': '66%'}}></div>
        <div onClick={() => changePriority(1)} className={prio1_cls.join(' ')} style={{'height': '33%'}}></div>
    </div>
}


function NoteTile(props: any) {
    const hasImage = props.note.title.indexOf('<img') !== -1
    let additional_style = {}
    if (hasImage) {
        additional_style = {'width': '100%'}
    }
    React.useEffect(() => {
        if (props.note.id === selectedTileId) {
            scrollDownIfNecessary(document.getElementById(props.note.id)!, document.getElementById('notebook-content')!, true)
        }
    }, [])
    let className = "notebook note-tile"
    if (props.isInTrash) {
        className += ' vanish'
    } else if (props.note.id === selectedTileId) {
        className += ' selected-tile'
    }
    async function _setPrio(_prio: number) {
        props.updateNotePrio(props.note, _prio)
    }
    return (
        <div id={props.note.id} onClick={props.displayNote} onContextMenu={props.onContextMenu}
             className={className}>
            <div className="tile-editor-container">
                <div className="tile-editor" style={additional_style} dangerouslySetInnerHTML={{__html: props.note.title.toString()}}>
                </div>
            </div>
            {
                props.openContext === props.note.id ? <div className="contextActions rotateX">
                    <a style={{color: "darkred"}} onClick={props.removeNote}>Remove</a>
                    <a onClick={props.clearContext}>Cancel</a>
                </div> : <TileSidebar prio={props.note.priority} setprio={_setPrio} />
            }
        </div>)
}


/*
* This element is only here to estimate when it has been rendered and run something after render.
*/
function _dummyElement() {
    React.useEffect(() => {
        for (let hook of post_notebook_view_render_hooks) {
            hook()
    }}, [])
    return <span></span>
}


export default function SingleNotebook() {
    const nbId = window.location.hash.replace("#", "")
    const [showDummy, setShowDummy] = useState(false)
    const [notebook, setNotebook] = useState({
        id: window.location.hash.replace("#", ""),
        title: "",
        secret: "",
        notes: []
    })
    const [openContext, setOpenContext] = useState('')
    const [deletedNote, setDeletedNote] = useState("")
    const deleteNoteTimer = React.useRef(0)

    const navigate = useNavigate();

    React.useEffect(() => {
        updateView()
        document.getElementById('notebook-content')!.focus()
    }, [])

    async function updateView() {
        const notebook = await loadNotebook(nbId)
        document.title = `foxtales - ${notebook.title}`
        const notes = []
        for (let noteId of notebook.notes) {
            notes.push(await loadNote(noteId))
        }
        // @ts-ignore
        notebook.notes = notes
        // @ts-ignore
        setNotebook(notebook)
        setShowDummy(true)
    }

    function onContextMenu(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault()
        // @ts-ignore
        setOpenContext(event.target.id)
    }

    function clearContext(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault()
        event.stopPropagation()
        let shouldUpdate = false;
        if (deleteNoteTimer.current) {
            clearTimeout(deleteNoteTimer.current);
            deleteNoteTimer.current = 0;
            shouldUpdate = true
        }
        // @ts-ignore
        event.target.parentElement!.classList.add('rotateXOut')
        setTimeout(async () => {
            setOpenContext("")
            if (shouldUpdate) {
                await updateView()
            }
        }, 150)
    }

    async function reallyDeleteNote(noteId: string) {
        await deleteNote(nbId, noteId)
        await updateView()
    }

    async function removeNote(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault()
        event.stopPropagation()
        const noteTile = document.getElementById(openContext)!
        try {noteTile.classList.remove('selected-tile'); selectedTileId=""} catch(e) {}
        _removeNote(openContext)
    }

    async function _removeNote(noteId: string) {
        if (deleteNoteTimer.current) {
            window.clearTimeout(deleteNoteTimer.current)
            deleteNoteTimer.current = 0;
            const theNoteId = deletedNote;
            await reallyDeleteNote(theNoteId)
            setDeletedNote('')
        }
        const noteTile = document.getElementById(noteId)!
        noteTile.classList.add('vanish')
        setTimeout(async () => {
            setOpenContext("")
            setDeletedNote(noteId)

            // @ts-ignore
            deleteNoteTimer.current = setTimeout(() => {
                const undo_delete = document.getElementById('undo-delete')
                if (undo_delete) {
                    undo_delete.classList.add('fade-out')
                }
                setTimeout(async () => {
                    setDeletedNote('')
                    await reallyDeleteNote(noteId)
                }, 300)
            }, 10000)
        }, 150)
    }

    async function displayNote(event: React.MouseEvent<HTMLDivElement>) {
        // @ts-ignore
        _displayNote(event.target.id)
    }

    async function _exportNote(noteId: string) {
        const note = await loadNote(noteId)
        downloadJson(JSON.stringify(note), `foxtales_${noteId}_${note.changed}.json`)
    }

    async function _importNote() {
        let file;
        try {
            // @ts-ignore
            [file] = await window.showOpenFilePicker()
        } catch(e) {
            return
        }
        const content = await file.getFile()
        console.log(content)
        const text = await content.text()
        const data = JSON.parse(text)

        const noteId = uuidv4()
        if (data.version !== NOTE_DATAVERSION) {
            console.error('Could not load note - unknown version')
        }
        selectedTileId = noteId
        await storeNote(nbId, {
            id: noteId,
            title: data.title,
            content: data.content,
            changed: data.changed,
            version: data.version,
            draw: data.draw,
            priority: data.priority
        })
        await updateView()
    }

    async function _displayNote(noteId: string) {
        const tile = document.getElementById(noteId)
        if (!tile) {return}
        if (deleteNoteTimer.current) {
            window.clearTimeout(deleteNoteTimer.current)
            deleteNoteTimer.current = 0;
            const theNoteId = deletedNote;
            await reallyDeleteNote(theNoteId)
            setDeletedNote('')
        }
        const full_uri = '/note#' + notebook.id + '/' + noteId
        const newNode: HTMLDivElement = tile.cloneNode(true) as HTMLDivElement
        newNode.style.position = 'fixed';
        const rect = tile.getBoundingClientRect()
        newNode.style.left = ''+rect.x
        newNode.style.top = ''+rect.y
        newNode.style.width = `${rect.width}px`
        newNode.style.height = `${rect.height}px`
        newNode.innerText = ''
        newNode.classList.add("expand")
        tile.parentNode!.appendChild(newNode)
        document.getElementById('add-anything')!.classList.add('fade-out')
        window.setTimeout(() => {
            navigate(full_uri)
        }, 150)
    }

    const updateNotePrio = async (note: INoteObject, prio: number) => {
        note.priority = prio
        await storeNote(notebook.id, note)
        await updateView()
    }

    const note_tiles = []
    // @ts-ignore
    let notes = notebook.notes.toSorted((a, b) => {return b.changed - a.changed})
    const notes_by_prio = [[], [], [], []]
    for (let note of notes) {
        // @ts-ignore
        notes_by_prio[note.priority].push(note)
    }
    notes = [...notes_by_prio[3], ...notes_by_prio[2], ...notes_by_prio[1], ...notes_by_prio[0]]

    for (let note of notes) {
        // @ts-ignore
        note_tiles.push(<NoteTile key={note.id} isInTrash={note.id === deletedNote} notebookId={notebook.id} note={note}
                                  displayNote={displayNote}
                                  onContextMenu={onContextMenu} updateNotePrio={updateNotePrio}
                                  openContext={openContext} removeNote={removeNote} clearContext={clearContext}/>)
    }

    // @ts-ignore
    async function createNewNote() {
        const noteId = uuidv4()
        selectedTileId = noteId
        const full_uri = `/note#${notebook.id}/${noteId}`
        const newNode = document.createElement('div')
        newNode.classList.add('notebook')
        newNode.style.position = 'fixed';
        newNode.style.right = "40px"
        newNode.style.bottom = "40px"
        newNode.style.width = `0px`
        newNode.style.height = `0px`
        newNode.innerText = ''
        newNode.classList.add("expand")
        document.getElementById("tile-container")!.appendChild(newNode)
        await storeNote(notebook.id, {
            id: noteId,
            title: "",
            content: {},
            changed: Date.now(),
            version: NOTE_DATAVERSION,
            draw: null,
            priority: 0
        })
        window.setTimeout(() => {
            navigate(full_uri)
        }, 150)
    }

    function backToRoot() {
        document.getElementById('notebook-title')!.classList.add('fade-out')
        window.setTimeout(() => navigate(-1), 150)
    }

    // @ts-ignore
    function preventPropagation(event) {
        event.preventDefault()
        event.stopPropagation()
    }

    async function restoreNote() {
        // @ts-ignore
        window.clearTimeout(deleteNoteTimer.current)
        deleteNoteTimer.current = 0;
        document.getElementById('undo-delete')!.classList.add('fade-out')
        document.getElementById('undo-delete-2')!.classList.add('fade-out')
        setTimeout(async () => {
            // @ts-ignore
            document.getElementById(deletedNote).classList.remove('vanish')
            setDeletedNote('')
            await updateView()
        }, 300)
    }

    function getSelectedNote(): INoteObject | undefined {
        for (let note of notebook.notes) {
            // @ts-ignore
            if (note.id === selectedTileId) {
                return note
            }
        }
    }

    function onkey(event: React.KeyboardEvent) {
        console.log('yay', event)
        if (event.key === 'Escape') {
            navigate(-1)
        } else if (event.key === '3' && selectedTileId) {
            const selectedNote = getSelectedNote()
            if (selectedNote) {
                updateNotePrio(selectedNote, selectedNote.priority === 3 ? 0 : 3)
            }
        }  else if (event.key === '2' && selectedTileId) {
            const selectedNote = getSelectedNote()
            if (selectedNote) {
                updateNotePrio(selectedNote, selectedNote.priority === 2 ? 0 : 2)
            }
        }  else if (event.key === '1' && selectedTileId) {
            const selectedNote = getSelectedNote()
            if (selectedNote) {
                updateNotePrio(selectedNote, selectedNote.priority === 1 ? 0 : 1)
            }
        }  else if (event.key === '0' && selectedTileId) {
            const selectedNote = getSelectedNote()
            if (selectedNote) {
                updateNotePrio(selectedNote, 0)
            }
        } else if (event.key === 'Enter' && selectedTileId) {
            _displayNote(selectedTileId)
        } else if (event.key === 'e' && selectedTileId) {
            _exportNote(selectedTileId)
        } else if (event.key === 'i' && selectedTileId) {
            _importNote()
         } else if (event.key === " " && event.ctrlKey) {
            const note = document.getElementById(selectedTileId)
            if (!note) {return}
            const links = Array.from(note.getElementsByTagName('a'))
            if (links.length === 0) {return}
            for (let link of links) {
                window.open(link.href, '_blank')
            }
        } else if (event.key === 'Delete' && selectedTileId) {
            const currentTile = document.getElementById(selectedTileId)
            const allTiles = Array.prototype.slice.call(document.body.getElementsByClassName('note-tile'))
            const newIndex = allTiles.indexOf(currentTile) + 1
            const tileToSelect = allTiles[Math.min(allTiles.length - 2, newIndex)]
            try {currentTile!.classList.remove('selected-tile')} catch(e) {}
            _removeNote(selectedTileId)
            selectedTileId = tileToSelect!.id
            tileToSelect!.classList.add('selected-tile')
        }  else if (event.key === '+') {
            createNewNote()
        } else if (isArrowNavigation(event)) {
            selectedTileId = arrowNavigation(event, 'note-tile', 'notebook-content', selectedTileId)
        }
    }

    return <div id="notebook-content" onClick={preventPropagation} onKeyDown={onkey} tabIndex={0}>
        <div className="notebook-titlebar fade-in">
            <div className="notebook-title" id='notebook-title'>{notebook.title}</div>
            <a className="titlebar-close" onClick={backToRoot}>X</a>
        </div>
        <main id="tile-container" className="App-content fade-in">
            {note_tiles}
        </main>
        <div id='button-bar'>
            {deletedNote ? <div id="undo-delete-container">
                <a id="undo-delete" className="fade-in circle-button" onClick={restoreNote}>
                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 256 256" enable-background="new 0 0 256 256">
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <path fill="var(--fill)"
                                                  d="M21,139.1c0-3.1,2.5-5.5,5.5-5.5h28.2c3,0,5.5,2.4,5.5,5.5c0,37.3,30.4,67.7,67.7,67.7c37.3,0,67.8-30.4,67.8-67.7c0-37.4-30.4-67.7-67.7-67.7c-3.9,0-7.9,0.3-11.8,1v19.1c0,2.2-1.3,4.3-3.4,5.1c-2.1,0.8-4.5,0.4-6.1-1.2L50.6,57.4c-2.1-2.1-2.1-5.6,0-7.8l56.2-38c1.6-1.6,3.9-2,6-1.2c2,0.9,3.4,2.9,3.4,5.1v17.3c4-0.4,7.9-0.7,11.8-0.7c59,0,107,48,107,107c0,59-48,106.9-107,106.9C69,246,21,198,21,139.1z"/>
                                        </g>
                                    </g>
                                </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                            </g>
                        </g>
                    </svg>
                </a>
                <div className='undo-delete-2-wrapper'><a onClick={restoreNote} id="undo-delete-2"
                                                          className="fade-in circle-button">
                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 256 256" enable-background="new 0 0 256 256">
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <path fill="var(--fill)"
                                                  d="M21,139.1c0-3.1,2.5-5.5,5.5-5.5h28.2c3,0,5.5,2.4,5.5,5.5c0,37.3,30.4,67.7,67.7,67.7c37.3,0,67.8-30.4,67.8-67.7c0-37.4-30.4-67.7-67.7-67.7c-3.9,0-7.9,0.3-11.8,1v19.1c0,2.2-1.3,4.3-3.4,5.1c-2.1,0.8-4.5,0.4-6.1-1.2L50.6,57.4c-2.1-2.1-2.1-5.6,0-7.8l56.2-38c1.6-1.6,3.9-2,6-1.2c2,0.9,3.4,2.9,3.4,5.1v17.3c4-0.4,7.9-0.7,11.8-0.7c59,0,107,48,107,107c0,59-48,106.9-107,106.9C69,246,21,198,21,139.1z"/>
                                        </g>
                                    </g>
                                </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>e
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                            </g>
                        </g>
                    </svg>
                </a></div>
            </div> : null}
            {showDummy ? <_dummyElement /> : null}
            <a onClick={createNewNote} id="add-anything" className="fade-in circle-button">
                <svg width="300" height="300" viewBox="0 0 300 300" fill="#fff">
                    <rect x="5" y="130" rx="10" ry="10" width="290" height="40"/>
                    <rect x="130" y="5" rx="10" ry="10" width="40" height="290"/>
                </svg>
            </a>
        </div>
    </div>
}
