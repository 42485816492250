import {NodeViewWrapper} from '@tiptap/react'
import React from 'react'
import {mergeAttributes, Node, nodeInputRule} from '@tiptap/core'
import {ReactNodeViewRenderer} from '@tiptap/react'
import {after_tile_rendered} from '../utils'
import './date.css';


function BlockComponent(props: any) {
    const date = new Date(props.node.attrs.date)
    function onchange(event: any) {
        props.updateAttributes({date: new Date(event.target.value).toUTCString()})
    }
    return (
        <NodeViewWrapper style={{'display': 'inline-block'}}>
            <span className="date" onChange={onchange}>{date.toISOString().slice(0, 10)}</span>
        </NodeViewWrapper>
    )
}


export default Node.create({
    name: 'date',

    group: 'inline',
    inline: true,
    selectable: true,
    atom: false,

    addAttributes() {
        return {
            date: {
                default: new Date().toUTCString()
            }

        }
    },

    parseHTML() {
        return [
            {
                tag: 'date',
            },
        ]
    },

    addKeyboardShortcuts() {
        return {}
    },

    renderHTML({node, HTMLAttributes}) {
        const date = new Date(node.attrs.date)
        return ['date', mergeAttributes(HTMLAttributes), date.toISOString().slice(0, 10)]
    },

    addNodeView() {
        return ReactNodeViewRenderer(BlockComponent)
    },

    addInputRules() {
        return [
            nodeInputRule({
                find: /\/today /,
                type: this.type,
            }),
        ]
    },
})