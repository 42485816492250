
import React from 'react';
import {useNavigate} from "react-router-dom";

import {TiptapNote} from "./tiptapNote";
import {Excalidraw} from "@excalidraw/excalidraw";


export default function SingleNote() {

    return (
        <div id="allnote-content">
            <TiptapNote />
        </div>
    );
}
