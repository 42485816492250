import {ExcalidrawElement} from "@excalidraw/excalidraw/types/element/types";
import {AppState, BinaryFiles, LibraryItems} from "@excalidraw/excalidraw/types/types";

export const NOTE_DATAVERSION = 1
export const NOTEBOOK_DATAVERSION = 0


export interface ExcalidrawData {
    appState: AppState
    elements: ExcalidrawElement[]
    files: BinaryFiles
}


export interface INoteObject{
    id: string,
    title: string,
    content: object,
    changed: number,
    version: number,
    priority: number,
    draw: ExcalidrawData | null
}


interface INotebookObject {
    id: string,
    title: string,
    secret: string,
    notes: string[],
    version: number
}

export interface ILibraryObject {
    id: string,
    items: LibraryItems
}


interface IBaseSettings {
    notebooks: string[],
    id?: string;
}

async function loadNote(id: string): Promise<INoteObject> {
    const note: INoteObject = await loadFromIndexedDB('notes', id) as INoteObject
    if (note.version === undefined) {
        // @ts-ignore
        note.content = note.content[0]
        note.version = 0
    }
    if (note.version < 1) {
        note.draw = null
    }
    if (note.priority === undefined) {
        note.priority = 0
    }
    return note
}

async function storeNote(notebookId: string, noteObject: INoteObject) {
    console.log('Store note', noteObject)
     await saveToIndexedDB('notes', noteObject)
     const notebook = await loadNotebook(notebookId)
     // @ts-ignore
     if (notebook.notes.indexOf(noteObject.id) === -1) {
     // @ts-ignore
        notebook.notes.push(noteObject.id)
     }
     // @ts-ignore
     await storeNotebook(notebook)
}

async function deleteNote(notebookId: string, noteIdentifier: string) {
    const notebook = await loadNotebook(notebookId)
     // @ts-ignore
    const filtered_notes = notebook.notes.filter(e => e !== noteIdentifier)
    // @ts-ignore
    notebook.notes = filtered_notes
    console.log(noteIdentifier, notebook)
     // @ts-ignore
    await storeNotebook(notebook)
    await deleteFromIndexedDB('notes', noteIdentifier)
}


function loadNotebook(id: string): Promise<INotebookObject> {
    return loadFromIndexedDB('notebooks', id) as Promise<INotebookObject>
}

export function loadLibrary(notebookId: string): Promise<ILibraryObject> {
    return loadFromIndexedDB('libraries', notebookId, {id: notebookId, items: []}) as Promise<ILibraryObject>
}

export async function storeLibrary(library: ILibraryObject) {
    return saveToIndexedDB('libraries', library)
}

async function storeNotebook(notebookObject: INotebookObject) {
    await saveToIndexedDB('notebooks', notebookObject)
    let baseSettings;
    try {
        baseSettings = await loadBaseSettings()
    } catch (e) {
        baseSettings = {notebooks: []}
    }
    // @ts-ignore
    if (baseSettings.notebooks.indexOf(notebookObject.id) === -1) {
        // @ts-ignore
        baseSettings.notebooks.push(notebookObject.id)
    }
    console.log('Will store', baseSettings)
    // @ts-ignore
    storeBaseSettings(baseSettings)
    return
}

function deleteNotebook(notebookIdentifier: string) {
    deleteFromIndexedDB('notebooks', notebookIdentifier)

    // @ts-ignore
    const baseSettings = loadBaseSettings()
    // @ts-ignore
    baseSettings.notebooks = baseSettings.notebooks.filter(e => e !== notebookIdentifier);
    // @ts-ignore
    storeBaseSettings(baseSettings)
    return
}

async function loadBaseSettings() {
    try {
        return await loadFromIndexedDB('foxtale', 'settings')
    } catch(e) {
        await storeBaseSettings({notebooks: []})
        return await loadFromIndexedDB('foxtale', 'settings')

    }
}

function storeBaseSettings(settings: IBaseSettings) {
    settings.id = 'settings'
    return saveToIndexedDB('foxtale', settings)
}


export {loadNote, storeNote, deleteNote, loadNotebook, storeNotebook, deleteNotebook, loadBaseSettings}


function deleteFromIndexedDB(storeName: string, identifier: string){
  return new Promise(
    function(resolve, reject) {
      var dbRequest = indexedDB.open(storeName);

      dbRequest.onerror = function(event) {
        reject(Error("IndexedDB database error"));
      };

      dbRequest.onupgradeneeded = function(event: any) {
        // @ts-ignore
        var database    = event.target.result;
        var objectStore = database.createObjectStore(storeName, {keyPath: "id"});
      };

      dbRequest.onsuccess = function(event: any) {
        // @ts-ignore
        var database      = event.target.result;
        var transaction   = database.transaction([storeName], 'readwrite');
        var objectStore   = transaction.objectStore(storeName);
        // @ts-ignore
        var objectRequest = objectStore.delete(identifier);

        objectRequest.onerror = function(event: any) {
          reject(Error('Error text'));
        };

        objectRequest.onsuccess = function(event: any) {
          resolve('Data saved OK');
        };
      };
    }
  );
}



function loadFromIndexedDB(storeName: string, id: string, defaultvalue?: any){
  return new Promise(
    function(resolve, reject) {
      var dbRequest = indexedDB.open(storeName);

      dbRequest.onerror = function(event: any) {
        reject(Error("Error text"));
      };

      dbRequest.onupgradeneeded = function(event: any) {
        // Objectstore does not exist. Nothing to load
        event.target.transaction.abort();
        if (defaultvalue) {
            resolve(defaultvalue)
        }
        reject(Error('Not found'));
      };

      dbRequest.onsuccess = function(event: any) {
        var database      = event.target.result;
        var transaction   = database.transaction([storeName]);
        var objectStore   = transaction.objectStore(storeName);
        var objectRequest = objectStore.get(id);

        objectRequest.onerror = function(event: any) {
          if (defaultvalue) {
            return resolve(defaultvalue)
          } else {
            reject(Error('Error text'));
          }
        };

        objectRequest.onsuccess = function(event: any) {
          if (objectRequest.result) resolve(objectRequest.result);
          else reject(Error('object not found'));
        };
      };
    }
  );
}


function saveToIndexedDB(storeName: string, object: any){
  return new Promise(
    function(resolve, reject) {
      if (object.id === undefined) reject(Error('object has no id.'));
      var dbRequest = indexedDB.open(storeName);

      dbRequest.onerror = function(event: any) {
        reject(Error("IndexedDB database error"));
      };

      dbRequest.onupgradeneeded = function(event: any) {
        var database    = event.target.result;
        var objectStore = database.createObjectStore(storeName, {keyPath: "id"});
      };

      dbRequest.onsuccess = function(event: any) {
        var database      = event.target.result;
        var transaction   = database.transaction([storeName], 'readwrite');
        var objectStore   = transaction.objectStore(storeName);
        var objectRequest = objectStore.put(object); // Overwrite if exists

        objectRequest.onerror = function(event: any) {
          reject(Error('Error text'));
        };

        objectRequest.onsuccess = function(event: any) {
          resolve('Data saved OK');
        };
      };
    }
  );
}