import React from "react";
import {EditorContent, generateHTML, JSONContent, useEditor} from '@tiptap/react'
import {createLowlight, common} from 'lowlight'
import {CodeBlockLowlight} from '../shenzhen/Codeblock'
import Checklist from "../shenzhen/checklist"
import DueDate from "../shenzhen/duedate"
import AnyDate from "../shenzhen/date"
import Focus from '@tiptap/extension-focus'
import DropCursor from '@tiptap/extension-dropcursor'
import Gapcursor from '@tiptap/extension-gapcursor'
import History from '@tiptap/extension-history'
import Bold from '@tiptap/extension-bold'
import Highlight from '@tiptap/extension-highlight'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import TextStyle from '@tiptap/extension-text-style'
import InlineCode from '@tiptap/extension-code'
import Link from '../shenzhen/link'
import Blockquote from '@tiptap/extension-blockquote'
import BulletList from '@tiptap/extension-bullet-list'
import Document from '@tiptap/extension-document'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import ListItem from '@tiptap/extension-list-item'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Image from '@tiptap/extension-image'
import Typography from '@tiptap/extension-typography'
import {
    storeNote,
    loadNote,
    NOTE_DATAVERSION, INoteObject,
    loadLibrary,
    storeLibrary, ILibraryObject
} from '../dbaccess'
import {Excalidraw} from "@excalidraw/excalidraw";
import {LibraryItems} from "@excalidraw/excalidraw/types/types";
import {useNavigate} from "react-router-dom";

const lowlight = createLowlight(common)


function SvgDrawer(props: any) {
    return <svg onClick={props.onClick} className={"openDraw"} viewBox="0 0 19 136" version="1.1" >
    <g transform="matrix(1,0,0,1,-1408.65,-389.051)">
        <path d="M1408.65,456.998C1408.65,441.017 1414.75,436.191 1417.98,429.921C1421.7,422.69 1426.97,402.592 1427.15,390.338L1427.15,389.051C1427.16,389.469 1427.16,389.898 1427.15,390.338L1427.15,457.017L1427.15,523.695C1427.16,524.135 1427.16,524.564 1427.15,524.982L1427.15,523.695C1426.97,511.442 1421.7,491.344 1417.98,484.112C1414.75,477.843 1408.65,473.017 1408.65,457.035L1408.65,457.035L1408.65,456.998L1408.65,456.998Z" fill={'var(--svg-bg)'}/>
    </g>
        <g transform="matrix(0.866025,0.5,-0.347826,0.602452,-1058.9,-916.794)">
        <rect x="1414.99" y="442.352" width="3.504" height="16.986" fill={'white'}/>
    </g>
        <g transform="matrix(-0.866025,-0.5,0.5,-0.866025,1007.67,1179.72)">
        <path d="M1421.22,458.686L1422.97,462.248L1419.47,462.248L1421.22,458.686Z" fill={'white'}/>
    </g>
        <g transform="matrix(1,0,0,1,-1408.86,-387.566)">
        <path d="M1414.49,460.517C1414.49,460.517 1413.21,463.286 1418.12,463.232C1423.02,463.177 1423.99,466.761 1418.94,467.304" fill={'none'} stroke={'white'}/>
    </g>
</svg>
}


function SvgHider(props: any) {
    return <svg onClick={props.onClick} className={"closeDraw"} viewBox="0 0 19 136" version="1.1" >
        <g transform="matrix(1,0,0,1,-1408.65,-389.051)">
            <path d="M1408.65,456.998C1408.65,441.017 1414.75,436.191 1417.98,429.921C1421.7,422.69 1426.97,402.592 1427.15,390.338L1427.15,389.051C1427.16,389.469 1427.16,389.898 1427.15,390.338L1427.15,457.017L1427.15,523.695C1427.16,524.135 1427.16,524.564 1427.15,524.982L1427.15,523.695C1426.97,511.442 1421.7,491.344 1417.98,484.112C1414.75,477.843 1408.65,473.017 1408.65,457.035L1408.65,457.035L1408.65,456.998L1408.65,456.998Z" fill={'var(--svg-bg)'}/>
        </g>
        <g transform="matrix(5.25861e-17,-0.858796,0.713776,4.37062e-17,-40.5446,76.1404)">
            <path d="M3.409,76.344L7.901,69.485L3.83,63.2L6.932,63.2L9.568,67.423L12.15,63.2L15.226,63.2L11.137,69.584L15.629,76.344L12.428,76.344L9.514,71.798L6.591,76.344L3.409,76.344Z" fill={"white"}/>
        </g>
    </svg>
}

export function TiptapNote() {
    const noteId = window.location.hash.replace("#", "")
    const [notebookId, singleNoteId] = noteId.split('/')
    const [noteIsLoaded, setNoteIsLoaded] = React.useState(false)
    const [showExcalidraw, setShowExcalidraw] = React.useState(false)
    const [excalidrawAPI, setExcalidrawAPI] = React.useState(null);

    const hasChanged = React.useRef(false)
    let timeout: any = React.useRef(null)
    let theNote: React.Ref<INoteObject | null> = React.useRef(null);
    let theEditor: React.Ref<INoteObject | null> = React.useRef(null);
    const extensions = [
        // Misc
        DropCursor,
        Gapcursor,
        History,
        Focus.configure({className: 'focused'}),
        Typography,
        // Markers
        Bold,
        Italic,
        Highlight,
        Strike,
        TextStyle,
        InlineCode,
        // Nodes
        Document,
        Paragraph,
        Image.configure({allowBase64: true}),
        Text,
        Blockquote,
        BulletList,
        HardBreak,
        Heading,
        ListItem,
        HorizontalRule,
        OrderedList,
        // Own/Overridden - Keyboard shortcuts are prioritized based on the order of the extensions added here.
        // Last added will have highest priority. In order to prevent unwanted behaviour, move own extensions here.
        DueDate,
        AnyDate,
        CodeBlockLowlight.configure({
            lowlight,
        }),
        Checklist,
        Link.configure({autolink: true, openOnClick: true}),
    ]
    let autofocus = false
    const someNavigator = useNavigate()
    // @ts-ignore
    try {autofocus = !navigator.userAgentData.mobile} catch(e) {}

    const editor = useEditor({
        autofocus: autofocus,
        onUpdate({editor}) {
            if (timeout.current) {
                window.clearTimeout(timeout.current)
            }
            timeout.current = window.setTimeout(() => {
                saveNote(editor.getJSON())
            }, 500)
            hasChanged.current = true
        },
        extensions,
        editorProps: {
            attributes: {
                class: "Editor"
            }
        },
        content: ""
    });

    async function onkey(event: KeyboardEvent) {
        if (event.key === "Escape") {
            await closeNote()
            event.preventDefault()
            event.stopPropagation()
        }
    }

    async function closeNote() {
        abortChange()
        // @ts-ignore
        await saveNote(theEditor.current.getJSON())
        someNavigator(-1)
    }

    React.useEffect(() => {
        const loadLib = async() => {
            if (excalidrawAPI) {
                const library = await loadLibrary(notebookId)
                console.log('Updatelib', library)
                // @ts-ignore
                excalidrawAPI.updateLibrary({libraryItems: library.items})
            }
        }
        loadLib()
    }, [excalidrawAPI])

    React.useEffect(() => {
        window.addEventListener('keyup', onkey)
        setTimeout(() => {
            try {
                if (showExcalidraw) {return}
                // @ts-ignore
                document.getElementsByClassName('tiptap')[0].focus()
            } catch(e){}
        }, 200)
        return () => {
            window.removeEventListener('keyup', onkey)
        }
    }, [])

    async function loadContent() {
        // @ts-ignore
        theEditor.current = editor
        const note = await loadNote(singleNoteId)
        editor?.commands.setContent(note.content)
        // @ts-ignore
        theNote.current = note
        document.title = `foxtales`
        setShowExcalidraw(note.draw !== null && note.draw.elements.length > 0)
        setNoteIsLoaded(true)
    }

    React.useEffect(() => {
        loadContent()
    }, [editor])

    async function saveNote(noteContent: JSONContent) {
        const titleContent = []
        for (let c of noteContent.content!.slice(0, 5)) {
            if (c.type === 'horizontalRule') {
                break
            }
            titleContent.push(c)
        }
        const json = {type: 'doc', content: titleContent}
        let draw;
        if (showExcalidraw && excalidrawAPI) {
            draw = {
                appState: {},
                // @ts-ignore
                elements: excalidrawAPI.getSceneElements(),
                // @ts-ignore
                files: excalidrawAPI.getFiles()
            }
            // @ts-ignore
            theNote.current!.draw = draw
        }
        if (hasChanged.current) {
            // @ts-ignore
            theNote.current.changed = Date.now()
            hasChanged.current = false
        }
        await storeNote(notebookId, {
            id: singleNoteId,
            title: generateHTML(json, extensions),
            content: noteContent,
            // @ts-ignore
            changed: theNote.current.changed,
            version: NOTE_DATAVERSION,
            // @ts-ignore
            draw: draw || theNote.current.draw,
            // @ts-ignore
            priority: theNote.current.priority
        })
    }

    function revealExcalidraw() {
        // @ts-ignore
        theNote.current!.draw = theNote.current!.draw || {appState: {}, elements: []}
        setShowExcalidraw(true)
    }

    async function hideExcalidraw() {
        if (timeout.current) {
            window.clearTimeout(timeout.current)
        }
        await saveNote(editor!.getJSON())
        setShowExcalidraw(false)
        setExcalidrawAPI(null)
    }

    function onExcaliChange()  {
        // Change after pointer is up: Excalidraw does not provide a way to detect when something has been changed.
        // The onChange prop is triggered for each mouseMove.
        if (timeout.current) {
            window.clearTimeout(timeout.current)
        }
        hasChanged.current = true
        timeout.current = window.setTimeout(() => {
            saveNote(editor!.getJSON())
        }, 1000)
    }

    function abortChange() {
        // Abort saving changes while pointer is down...
        if (timeout.current) {
            window.clearTimeout(timeout.current)
        }
    }

    function onLibChange(items: LibraryItems) {
        storeLibrary({id: notebookId, items})
    }

    if (!noteIsLoaded) {
        return <div>Loading...</div>
    } else {
        const showDrawArea = theNote.current!.draw && showExcalidraw
        const editorClasses = ['tiptapWrapper']
        if (showDrawArea) {
            editorClasses.push('with-excalidraw')
        }
        const elements = [<div key="tiptapwrapper" className={editorClasses.join(" ")}><EditorContent editor={editor}/></div>]
        if (showDrawArea) {
            elements.push(<div key="excalidrawwrapper" className={'excalidrawWrapper'} onPointerDown={abortChange} onPointerUp={onExcaliChange}>
                <SvgHider onClick={hideExcalidraw}/>
                <Excalidraw
                    // @ts-ignore
                    excalidrawAPI={(api)=> setExcalidrawAPI(api)}
                    onLibraryChange={onLibChange}
                    initialData={{
                    elements: theNote.current!.draw!.elements,
                    appState: theNote.current!.draw!.appState,
                    files: theNote.current!.draw!.files || [],
                }}/></div>)
        } else {
            elements.push(<SvgDrawer key="svgdrawer" onClick={revealExcalidraw}/>)
        }
        elements.push(<a key="closenote" className="close-note" onClick={closeNote}>X</a>)
        return <div className={"singleNoteContent"}>{elements}</div>
    }
}