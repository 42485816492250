export function isArrowNavigation(event: React.KeyboardEvent) {
    return ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(event.key) !== -1
}
export function arrowNavigation(event: any, tileCls: string, containerCls: string, selectedTileId: string) {
    event.preventDefault()
    event.stopPropagation()
    const currentTile = document.getElementById(selectedTileId)
    let tileToSelect = null
    const all_tiles = Array.prototype.slice.call(document.body.getElementsByClassName(tileCls))
    if (!currentTile) {
        // @ts-ignore
        tileToSelect = all_tiles[0]
    } else {
        try {
            currentTile.classList.remove('selected-tile')
        } catch(e) {}
        const {top, bottom, left, right, width, height} = currentTile.getBoundingClientRect()
        const [halfWidth, halfHeight] = [width / 4, height / 4]
        const container = document.getElementById(containerCls)!
        switch (event.key) {
            case 'ArrowRight':
                tileToSelect = document.elementFromPoint(right + halfWidth, top + halfHeight)
                if (!tileToSelect || !tileToSelect.classList.contains(tileCls)) {
                    tileToSelect = all_tiles[Math.min(all_tiles.length - 1, all_tiles.indexOf(currentTile) + 1)]
                    scrollDownIfNecessary(tileToSelect, container)
                }
                break;
            case 'ArrowDown':
                tileToSelect = document.elementFromPoint(left + halfWidth, bottom + halfHeight)
                if (!tileToSelect || !tileToSelect.classList.contains(tileCls)) {
                    tileToSelect = all_tiles[all_tiles.length - 1]
                }
                console.log('ALLTI', all_tiles, tileToSelect, container)
                scrollDownIfNecessary(tileToSelect, container)
                break;
            case 'ArrowLeft':
                tileToSelect = document.elementFromPoint(left - halfWidth, top + halfHeight)
                if (!tileToSelect || !tileToSelect.classList.contains(tileCls)) {
                    tileToSelect = all_tiles[Math.max(0, all_tiles.indexOf(currentTile) - 1)]
                    scrollUpIfNecessary(tileToSelect, container)
                }
                break;
            case 'ArrowUp':
                tileToSelect = document.elementFromPoint(left + halfWidth, Math.max(5, top - halfHeight))
                if (!tileToSelect || !tileToSelect.classList.contains(tileCls)) {
                    tileToSelect = all_tiles[0]
                }
                scrollUpIfNecessary(tileToSelect, container)
                break;
        }
    }
    tileToSelect!.classList.add('selected-tile')
    return tileToSelect!.id
}



export function scrollDownIfNecessary(child: HTMLElement, container: HTMLElement, directScroll?: boolean) {
    let tileBottom = child.offsetTop + child.clientHeight
    let viewportBottom = container.scrollTop + container.clientHeight
    let scrollBy = tileBottom - viewportBottom + child.clientHeight / 3 * 2
    const behavior = directScroll ? 'auto' : 'smooth'
    if (scrollBy > 0) {container.scrollBy({left: 0, top: scrollBy, behavior: behavior})}
}
export function scrollUpIfNecessary(child: HTMLElement, container: HTMLElement, directScroll?: boolean) {
    let tileTop = child.offsetTop
    let viewportTop = container.scrollTop
    let scrollBy = tileTop - viewportTop - child.clientHeight / 3 * 1
    const behavior = directScroll ? 'auto' : 'smooth'
    if (scrollBy < 0) {container.scrollBy({left: 0, top: scrollBy, behavior: behavior})}
}