import {NodeViewContent, NodeViewWrapper} from '@tiptap/react'
import React from 'react'
import {mergeAttributes, Node, textblockTypeInputRule} from '@tiptap/core'
import {ReactNodeViewRenderer} from '@tiptap/react'
import './checklist.css';


function ChecklistComponent(props: any) {
    const classname = props.node.attrs.checked ? 'checkbox checked' : 'checkbox'

    function toggle(event: any) {
        event.preventDefault()
        event.stopPropagation()
        props.updateAttributes({checked: !props.node.attrs.checked})
    }

    return (
        <NodeViewWrapper className="checkbox-entry">
            <span className={classname} contentEditable={false} onMouseDown={toggle}></span>
            <NodeViewContent className="content" style={{"display": "inline-block"}}/>
        </NodeViewWrapper>
    )
}


export default Node.create({
    name: 'checklist',

    group: 'block',

    content: 'inline*',

    addAttributes() {
        return {
            checked: {
                default: false,
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'checklist',
            },
        ]
    },

    addKeyboardShortcuts() {
        return {
            'Ctrl-Space': (a) => {
                return this.editor.commands.updateAttributes('checklist',
                    {'checked': !this.editor.getAttributes('checklist').checked})
            },
            'Mod-Enter': () => {
                return this.editor.chain().insertContentAt(this.editor.state.selection.head, {type: this.type.name}).focus().run()
            },
            'Enter': () => {
                try {
                    // Add a new checkbox when hit return.
                    // @ts-ignore
                    if (this.editor.state.selection.ranges[0].$from.path[3].type.name === 'checklist') {
                        // @ts-ignore
                        if (this.editor.state.selection.$anchor.path[3].content.size > 0) {
                            // When there is content in the current checkbox node: Add new checkbox on return.
                            return this.editor.chain().insertContentAt(this.editor.state.selection.head, {type: this.type.name}).focus().run()
                        } else {
                            // If current checkbox node is empty, add normal paragraph.
                            return this.editor.chain().insertContentAt(this.editor.state.selection.head, {type: 'paragraph'}).focus().run()
                        }
                    }
                } catch(e) {}
                // If currently selected element is not a checklist, or an error happened, proceed with default
                // handling.
                return false
            }
        }
    },

    renderHTML({HTMLAttributes}) {
        return ['checklist', mergeAttributes(HTMLAttributes), 0]
    },

    addNodeView() {
        return ReactNodeViewRenderer(ChecklistComponent)
    },

    addInputRules() {
        return [
            textblockTypeInputRule({
                find: /^\[(x?)] $/,
                type: this.type,
                getAttributes: match => {
                    return {
                        checked: match[match.length - 1] === 'x',
                    }
                },
            }),
        ]
    },
})