import React, {ChangeEvent, useState} from "react";
import {loadBaseSettings, loadNotebook, NOTEBOOK_DATAVERSION, storeNotebook} from "../dbaccess";
import {useLocation, useNavigate} from "react-router-dom";
import {uuidv4} from "../utils";
import {isArrowNavigation, arrowNavigation, scrollDownIfNecessary} from '../utils/navigation'


let selectedNotebook = '';


function CreateNotebookTile() {
    const [state, setState] = useState({transition: 0})
    const [nbName, setNbName] = useState("")
    const [nbSecret, setNbSecret] = useState("")

    function enter_notebook() {
        setState({transition: 1})
        window.location.hash = '#add-notebook'
    }

    function onNameChange(event: ChangeEvent<HTMLInputElement>) {
        setState({transition: 2})
        setNbName(event.target.value)
    }

    function onSecretChange(event: ChangeEvent<HTMLInputElement>) {
        setNbSecret(event.target.value)
    }

    function show_enter_password(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault()
        event.stopPropagation()
        setState({transition: 3})
    }

    // @ts-ignore
    async function create_notebook(event) {
        event.preventDefault()
        event.stopPropagation()
        setState({transition: 0})
        await storeNotebook({id: uuidv4(), title: nbName, secret: nbSecret, notes: [], version: NOTEBOOK_DATAVERSION})
        window.location.hash = ''
    }

    return <div className="notebook" id="add-notebook" onClick={enter_notebook}>
        {state.transition === 0 ? <span>Add Notebook</span> : null}
        {state.transition === 1 || state.transition === 2 ?
            <input autoFocus onChange={onNameChange} value={nbName} placeholder={"Enter name..."}
                   type={"text"}/> : null}
        {state.transition === 2 ? <button onClick={show_enter_password}>Next</button> : null}
        {state.transition === 3 ?
            <input value={nbSecret} onChange={onSecretChange} autoFocus placeholder={"Enter passphrase..."}
                   type={"password"}/> : null}
        {state.transition === 3 ? <button onClick={create_notebook}>Create Notebook</button> : null}
    </div>
}


export default function NotebookOverview() {
    const [notebooks, setNotebooks] = useState([])
    const navigate = useNavigate()

    function opennotebook(event: any) {
        _opennotebook(event.target.id)
    }

    function _opennotebook(notebookId: string) {
        document.getElementById('notebook-title')!.classList.add('fade-out')
        setTimeout(() => navigate(`/notebook#${notebookId}`), 200)
    }

    React.useEffect(() => {
        updateNotebookView()
    }, [useLocation()])

    React.useEffect(() => {
        document.getElementById('notebook-content')!.focus()
    }, [])

    async function updateNotebookView() {
        const settings = await loadBaseSettings()
        const nbs = []
        // @ts-ignore
        for (let nbId of settings.notebooks) {
            const notebook = await loadNotebook(nbId)
            nbs.push(notebook)
        }
        // @ts-ignore
        setNotebooks(nbs)
    }

    const nbTiles = []
    for (let nb of notebooks) {
        // @ts-ignore
        const clsName = (nb.id === selectedNotebook) ? 'notebook selected-tile' : 'notebook'
        // @ts-ignore
        nbTiles.push(<div onClick={opennotebook} key={nb.id} id={nb.id} className={clsName}>
            {/*@ts-ignore*/}
            <span className='ignore-pointer'>{nb.title}</span></div>)
    }
    function onkey(event: React.KeyboardEvent) {
        if (isArrowNavigation(event)) {
            selectedNotebook = arrowNavigation(event, 'notebook', 'notebook-content', selectedNotebook)
        } else if (event.key === 'Enter' && selectedNotebook) {
            _opennotebook(selectedNotebook)
        }
    }

    return (
        <div id="notebook-content" onKeyDown={onkey} tabIndex={0}>
            <div className="foxtale-title fade-in" id='notebook-title'>Foxtales</div>
            <img className={"bglogo"} src={"bglogo.png"} alt={""}/>
            <main className="App-content fade-in">
                {nbTiles}
                <CreateNotebookTile/>
            </main>
        </div>
    );
}