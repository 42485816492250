import {NodeViewWrapper} from '@tiptap/react'
import React from 'react'
import {mergeAttributes, Node, nodeInputRule} from '@tiptap/core'
import {ReactNodeViewRenderer} from '@tiptap/react'
import {after_tile_rendered} from '../utils'
import './duedate.css';


function highlight_html() {
    const elems = Array.from(document.getElementsByTagName('duedate'))
    for (let elem of elems) {
        // @ts-ignore
        const date = new Date(elem.getAttribute('date')!)
        let additionalStyle = {}
        if (date < new Date()) {
            // @ts-ignore
            elem.style.color = '#f00'
        }
    }
}
after_tile_rendered(highlight_html)


function BlockComponent(props: any) {
    const date = new Date(props.node.attrs.date)
    function onchange(event: any) {
        props.updateAttributes({date: new Date(event.target.value).toUTCString()})
    }
    return (
        <NodeViewWrapper style={{'display': 'inline-block'}}>
            <input type="date" value={`${date.toISOString().slice(0, 10)}`} onChange={onchange} contentEditable={false} />
        </NodeViewWrapper>
    )
}


export default Node.create({
    name: 'duedate',

    group: 'inline',
    inline: true,
    selectable: true,
    atom: true,

    addAttributes() {
        return {
            date: {
                default: new Date().toUTCString()
            }

        }
    },

    parseHTML() {
        return [
            {
                tag: 'duedate',
            },
        ]
    },

    addKeyboardShortcuts() {
        return {}
    },

    renderHTML({node, HTMLAttributes}) {
        const date = new Date(node.attrs.date)
        return ['duedate', mergeAttributes(HTMLAttributes), date.toISOString().slice(0, 10)]
    },

    addNodeView() {
        return ReactNodeViewRenderer(BlockComponent)
    },

    addInputRules() {
        return [
            nodeInputRule({
                find: /\/due /,
                type: this.type,
            }),
        ]
    },
})