export function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        // @ts-ignore
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const post_notebook_view_render_hooks: any = []

export function after_tile_rendered(callback: () => void) {
    post_notebook_view_render_hooks.push(callback)
}

export function downloadJson(content: string, fileName: string) {
    var a = document.createElement("a");
    var file = new Blob([content], {type: 'text/plain'});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}